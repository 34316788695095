// outsource dependencies
import { useAccount } from "wagmi";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, Container, TextField, Typography, useMediaQuery } from "@mui/material";

// local dependencies
import { API } from "../../services/request";
import { ToastNotify } from "../../ToastNotify";
import { LOCAL_STORAGE_KEY } from "../../constants";
import { TransferStepper } from "../TransferStepper";
import { useMLMContract } from "../../ConnectivityAss/hooks";

const steps = ['Initiated', 'Progress', 'Confirmed'];
export function UpdateWallet () {
  const { address } = useAccount();
  const MLMContract = useMLMContract();

  const [params] = useSearchParams();

  const [activeStep, setActiveStep] = useState(1);
  const [isOpenedStepper, setIsOpenedStepper] = useState(false);
  const [alertState, setAlertState] = useState({ open: false, message: "", severity: null });

  const matches = useMediaQuery("(min-width : 630px)");

  const { handleSubmit, control, formState: { errors }, } = useForm({ mode: 'onBlur' });
  const username = params.get('username') || '';

  const handleUpdateWallet = async (data) => {
    try {
      if (!username) {
        throw Error('No required username param');
      }
      setActiveStep(1);
      setIsOpenedStepper(true);

      await MLMContract.updateUserWallet(data.newAddress);
      
      localStorage.removeItem(LOCAL_STORAGE_KEY.PAYMENT);
      localStorage.removeItem(LOCAL_STORAGE_KEY.LINK_ACCOUNT);
      // eslint-disable-next-line camelcase
      await API({ method: "POST", url: '/mlm/wallet', data: { username, old_wallet: address, new_wallet: data.newAddress } }).then((res)=>{
        const isSuccessful = res?.data?.codice === 'OK';
        const alertMessage = isSuccessful ? 'Success' : `MLM request error: ${res?.data?.descrizione}`;
        const alertSeverity = isSuccessful ? 'success' : 'error';
        setAlertState({ open: true, severity: alertSeverity, message: alertMessage });
      })
      setActiveStep(steps.length);
    } catch (error) {
      if (error.code === 'ACTION_REJECTED') {
        setAlertState({ open: true, severity: "error", message: 'User rejected transaction' });
      } else {
        const errorMessage = error.reason || error.message || "Went something wrong";
        setAlertState({ open: true, severity: "error", message: errorMessage });
      }
      setIsOpenedStepper(false);
      setActiveStep(1);
    }
  };

  return <Box py={2}>
    <ToastNotify alertState={alertState} setAlertState={setAlertState} />

    <Container>
      <Box>
        <img src="images/seaLogo.png" width="130px" height="130px" alt="Brand" />
      </Box>
      <Box marginX="auto" mt={8} width="100%" maxWidth={660} bgcolor="#EDF1FE" boxShadow={3}>
        <Box py={4} borderRadius="5px" px={matches ? 6 : 4}>
          <Box
            mb={8}
            color="black"
            fontWeight="500"
            textAlign="center"
            fontFamily="Inter"
            fontSize={{ md: "30px", xs: "22px" }}
          >SE@ Wallet Update</Box>

          <Box mb={2}>
            <Typography sx={{ color: "black", fontFamily: "Inter" }}>Nickname</Typography>
            <TextField
              readOnly
              fullWidth
              disabled
              size="small"
              value={username}
              variant="outlined"
              placeholder="John Doe"
              sx={{
                "& .MuiOutlinedInput-root": { bgcolor: "#fff" },
                "& .MuiInputBase-input": { color: 'black' }
              }}
            />
          </Box>

          <Box mb={2}>
            <Typography sx={{ color: "black", fontFamily: "Inter" }}>Current Address</Typography>
            <TextField
              readOnly
              fullWidth
              disabled
              size="small"
              variant="outlined"
              value={address || ''}
              placeholder="0x567890ds98f675eda70990s8dfa0"
              sx={{
                "& .MuiInputBase-input": { color: 'black' },
                "& .MuiOutlinedInput-root": { bgcolor: "#fff" },
              }}
            />
          </Box>

          <Box component="form" onSubmit={handleSubmit(handleUpdateWallet)}>
            <Box mb={2}>
              <Typography sx={{ color: "black", fontFamily: "Inter" }}>New Address</Typography>
              <Controller
                defaultValue=""
                name="newAddress"
                control={control}
                rules={{
                  required: "New address is required",
                  validate: value => value !== address || "New address must be different from the current address",
                  pattern: {
                    value: /^0x[a-fA-F0-9]{40}$/, // address regex
                    message: "Invalid address format"
                  },
                }}
                render={({ field }) => {
                  return <TextField
                    {...field}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.newAddress}
                    placeholder="0x567890ds98f675eda70990s8dfa0"
                    helperText={errors.newAddress ? errors.newAddress.message : ''}
                    sx={{
                      "& .MuiOutlinedInput-root": { bgcolor: "#fff" },
                      "& .MuiInputBase-input": { color: 'black' }
                    }}
                  />;
                }}
              />
            </Box>
            <Box mt={5} textAlign="center">
              <Button
                type="submit"
                sx={{
                  width: "90%",
                  border: "none",
                  color: "white",
                  fontWeight: "700",
                  fontFamily: "Inter",
                  textTransform: 'none',
                  background: "#0071BD",
                  fontSize: matches ? "20px" : "25px",
                  "&:hover": { background: "#0071BDa1" },
                }}
              >Update</Button>
              <TransferStepper
                steps={steps}
                title="Update Wallet"
                activeStep={activeStep}
                isOpened={isOpenedStepper}
                setActiveStep={setActiveStep}
                onClose={() => setIsOpenedStepper(false)}
                description="The updating process has been executed successfully"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  </Box>;
}
