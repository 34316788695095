// outsource dependencies
import moment from "moment";
import { useAccount } from "wagmi";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Container, Button, useMediaQuery, Typography, TextField } from "@mui/material";

// local dependencies
import { ENV } from "../../constants/env";
import { API } from "../../services/request";
import Stepper from "../stepper/stepperMain";
import { ToastNotify } from "../../ToastNotify";
import { TransferStepper } from "../TransferStepper";
import { useMLMContract, useTokenContract } from "../../ConnectivityAss/hooks";
import web3 from "web3";
import { ethers } from "ethers";

const requiredParams = [
  "USER_NAME",
  "WALLET",
  "IMPORTO",
  "PRODOTTO",
  "ORDER_ID",
  "MESI",
  "DATE_PAG",
  "NOME_PROD",
  "TAGLIO",
  "COM1_USER",
  "COM2_USER",
  "COMP1_WALLET",
  "COMP1_PERCENT",
  "COMP2_WALLET",
  "COMP2_PERCENT",
  "RENTAL",
  "FEE"
];

const validateRequiredParams = (params) => {
  return requiredParams.every(param => {
    return params.has(param);
  });
};

export function PaymentPage ({ setopenModal }) {
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  
  const [userData, setUserData] = useState();
  const handleCloseStepper = () => setOpenStepper(false);
  const [isStepperOpened, setOpenStepper] = useState(false);
  const [alertState, setAlertState] = useState({ open: false, message: "", severity: undefined, });

  const matches = useMediaQuery("(min-width : 630px)");

  useEffect(() => {
    const storedData = localStorage.getItem('payment');
    const areParams = validateRequiredParams(params);
    if (!storedData && !areParams) {
      return setAlertState({ open: true, message: 'Missing required parameters', severity: 'error' });
    }
    const userData = requiredParams.reduce((acc, param) => {
      acc[param] = params.get(param);
      return acc;
    }, {});
    setUserData(JSON.parse(storedData) || userData);
    if (areParams) {
      localStorage.setItem('payment', JSON.stringify(userData));
      navigate('?', { replace: true });
    }
  }, [location, navigate, params, setParams]);
  

  return <Box py={2}>
    <ToastNotify alertState={alertState} setAlertState={setAlertState} />

    <Container>
      <Box>
        <img src="images/seaLogo.png" width="130px" height="130px" alt="" />
      </Box>

      <Box pt={8}>
        <Container maxWidth="sm">
          <Box
            py={4}
            boxShadow={3}
            bgcolor="#EDF1FE"
            borderRadius="5px"
            px={matches ? 6 : 4}
          >
            <Box
              color="black"
              fontWeight="500"
              textAlign="center"
              fontFamily="Inter"
              fontSize={{ md: "30px", xs: "22px" }}
            >SE@ Payment System</Box>
            <Box sx={{ mb: 1 }}>
              <Typography sx={{ color: "black", fontFamily: "Inter" }}>Nickname</Typography>
              <TextField
                readOnly
                fullWidth
                size="small"
                variant="outlined"
                placeholder="John Doe"
                value={userData?.USER_NAME || ''}
                sx={{
                  "& .MuiOutlinedInput-root": { bgcolor: "#fff" },
                  "& .MuiInputBase-input": { color: 'black' }
                }}
              />
            </Box>
              
            <Box sx={{ mb: 1 }}>
              <Typography sx={{ color: "black", fontFamily: "Inter" }}>Wallet Address</Typography>
              <TextField
                readOnly
                fullWidth
                size="small"
                variant="outlined"
                value={userData?.WALLET || ''}
                placeholder="0x567890ds98f675eda70990s8dfa0"
                sx={{
                  "& .MuiOutlinedInput-root": { bgcolor: "#fff" },
                  "& .MuiInputBase-input": { color: 'black' }
                }}
              />
            </Box>

            <Box>
              <Typography sx={{ color: "black", fontFamily: "Inter" }}>Amount (USDT)</Typography>
              <TextField
                readOnly
                fullWidth
                size="small"
                variant="outlined"
                placeholder="1000"
                value={userData?.IMPORTO || ''}
                sx={{
                  "& .MuiOutlinedInput-root": { bgcolor: "#fff" },
                  "& .MuiInputBase-input": { color: 'black' }
                }}
              />
            </Box>
            <Box mt={5} textAlign="center">
              {isConnected ? <>
                <SubmitButton userData={userData} setAlertState={setAlertState} />
                <Dialog open={isStepperOpened} onClose={handleCloseStepper} style={{ width: "100%" }}>
                  <Stepper actualdata={userData} />
                </Dialog>
              </> : <Button
                onClick={() => setopenModal(true)}
                sx={{
                  width: "90%",
                  color: "white",
                  fontWeight: "700",
                  fontFamily: "Inter",
                  background: "#0071BD",
                  fontSize: { md: "18px", xs: "14px" },
                  "&:hover": { background: "#0071BDa1" },
                }}
              >connect</Button>}
            </Box>
          </Box>
        </Container>
      </Box>
    </Container>
  </Box>;
}

const steps = ['Initiated', 'Progress', 'Confirmed'];

function SubmitButton ({ userData=null, setAlertState=() => void(0) }) {
  const [isOpenedStepper, setIsOpenedStepper] = useState(false);

  const MLMToken = useTokenContract();
  const MLMContract = useMLMContract();
  const handleSubmit = async () => {
    try {
      const tokenDecimals = Number(ENV.TOKEN_AMOUNT_MULTIPLIER);
      console.log('tokenMultiplier:', tokenDecimals.toString());
      const importoAmount = ethers.utils.parseUnits(userData.IMPORTO, tokenDecimals);
      console.log('importoAmount', importoAmount.toString());
      const rentalAmount = ethers.utils.parseUnits(userData.RENTAL, tokenDecimals);// RENTAL * tokenMultiplier
      console.log('rentalAmount',rentalAmount.toString());
      const feeAmount = ethers.utils.parseUnits(userData.FEE, tokenDecimals); // FEE * tokenMultiplier
      console.log('feeAmount',feeAmount.toString());
      console.log('COMP1_PERCENT AND COMP2_PERCENT', Number(userData.COMP1_PERCENT), Number(userData.COMP2_PERCENT))
      const valore = Number(userData.IMPORTO) - (Number(userData.RENTAL)+Number(userData.FEE));
      console.log('valore', valore)
      const product_name = userData.PRODOTTO + userData.TAGLIO
      console.log('product_name', product_name)
      const amountStr = importoAmount.toString();
      const hexAmount = web3.utils.toHex(amountStr);
      console.log('approve hexAmount', hexAmount)
      const approve = await MLMToken.approve(ENV.CONTRACT_ADDRESS, hexAmount);
      console.log('approve success', approve)
      await MLMContract.deposit(
        importoAmount, // amount
        // eslint-disable-next-line camelcase
        product_name, // product_name
        // eslint-disable-next-line camelcase
        rentalAmount, // rental_amount
        // eslint-disable-next-line camelcase
        feeAmount, // fee
        // eslint-disable-next-line camelcase
        userData.COMP1_WALLET, // comp1_user
        // eslint-disable-next-line camelcase
        userData.COMP2_WALLET, // comp2_user
        // eslint-disable-next-line camelcase
        Number(userData.COMP1_PERCENT), // comp1_amount
        // eslint-disable-next-line camelcase
          Number(userData.COMP2_PERCENT), // comp2_amount
      );
      await API({ method: 'POST', url: '/mlm/payment', data: {
        username: userData.USER_NAME,             // "Alex"
        // eslint-disable-next-line camelcase
        wallet_address: userData.WALLET,          // "0x6578907654789076"
        // eslint-disable-next-line camelcase
        e_id: userData.ORDER_ID,                  // "fuasdjkahshdlas"
        data: userData.DATE_PAG,                  // "08/08/2024"
        ora: moment(Date.now()).format('HH:mm'),  // "16:16"
        // eslint-disable-next-line camelcase
        data_inizio: userData.DATE_PAG,           // "08/08/2024"// "BASIC"
        // eslint-disable-next-line camelcase
        codice_prodotto: userData.PRODOTTO,      // "BASIC250"
        descrizione: userData.NOME_PROD,          // "BASIC250"
        valore,                 //  IMPORTO-(RENTAL+FEE)
        noleggio: Number(userData.RENTAL),                // 350
        spese: Number(userData.FEE),                      // 50
        mesi: Number(userData.MESI),                      // 12
        // eslint-disable-next-line camelcase
        agente_rs_1: userData.COM1_USER,          // "user1"
        // eslint-disable-next-line camelcase
        perc_rs_1: Number(userData.COMP1_PERCENT),        // 10
        // eslint-disable-next-line camelcase
        agente_rs_2: userData.COM2_USER,          // "user2"
        // eslint-disable-next-line camelcase
        perc_rs_2: Number(userData.COMP2_PERCENT)         // 5
      } }).then((res) => {

        const isSuccessful = res?.data?.codice === 'OK';
        const alertMessage = isSuccessful ? 'Success' : `MLM request error: ${res?.data?.descrizione}`;
        const alertSeverity = isSuccessful ? 'success' : 'error';
        setAlertState({ open: true, severity: alertSeverity, message: alertMessage });

        if (isSuccessful) {
          window.location.href = ENV.PAYMENT_SUCCESS_REDIRECT;
        }
      });
    } catch (error) {
      console.log('error', error);
      if (error.code === 'ACTION_REJECTED') {
        setAlertState({ open: true, severity: "error", message: 'User rejected transaction' });
      } else {
        const errorMessage = error.reason || error.message || "Went something wrong";
        setAlertState({ open: true, severity: "error", message: errorMessage });
      }
      return error;
    }
  };

  return <>
    <Button
      onClick={() => {
        if (!userData) {
          return setAlertState({ open: true, severity: "error", message: 'No required data to start payment' });
        }
        setIsOpenedStepper(true);
      }}
      sx={{
        width: "90%",
        color: "white",
        fontWeight: "700",
        fontFamily: "Inter",
        textTransform: 'none',
        background: "#0071BD",
        fontSize: { md: "18px", xs: "14px" },
        "&:hover": { background: "#0071BDa1" },
      }}
    >
      <span style={{ textTransform: 'uppercase' }}>pay</span>
    </Button>
    <TransferStepper
      steps={steps}
      title="Transfer"
      handleWait={handleSubmit}
      isOpened={isOpenedStepper}
      onClose={() => setIsOpenedStepper(false)}
      description="Your transfer has been successfully completed. You will receive shortly a confirmation email in your account and you can check your profile to view the new package"
    />
  </>;
}
