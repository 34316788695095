// outsource dependencies
import { useAccount } from "wagmi";
import React, {useEffect, useState} from "react";
import { Box, Container, useMediaQuery, Typography, TextField, Button } from "@mui/material";

// local dependencies
import { ToastNotify } from "../../ToastNotify";
import { useMLMContract } from "../../ConnectivityAss/hooks";
import { ENV } from "../../constants/env";
import { ethers } from "ethers";

export function ClaimMain () {
  const { address } = useAccount();
  const matches = useMediaQuery("(min-width : 630px)");
  const MLMContract = useMLMContract();
  const [claimAmount, setClaimAmount] = useState('0')

  useEffect(() => {
    const fetchApprovedAmounts = async () => {
      if (!MLMContract?.provider) return
      try {
        const approvedAmounts = await MLMContract?.approvedAmounts(address);
        const claim = ethers.utils.formatUnits(approvedAmounts, ENV.TOKEN_AMOUNT_MULTIPLIER)
        setClaimAmount(claim)
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchApprovedAmounts();
  }, [MLMContract]);

  const [alertState, setAlertState] = useState({ open: false, message: "", severity: undefined });

  return <Box py={2}>
    <ToastNotify alertState={alertState} setAlertState={setAlertState} />

    <Container>
      <Box>
        <img src="images/seaLogo.png" width="130px" height="130px" alt="Brand" />
      </Box>
      <Box marginX="auto" mt={8} width="100%" maxWidth={790} bgcolor="#EDF1FE" boxShadow={3}>
        <Box py={4} borderRadius="5px" px={matches ? 6 : 4} >
          <Box
            color="black"
            fontWeight="500"
            textAlign="center"
            fontFamily="Inter"
            fontSize={{ md: "30px", xs: "22px" }}
          >SE@ Payment System</Box>
          <Box mb={2}>
            <Typography sx={{ color: "black", fontFamily: "Inter" }}>Amount</Typography>
            <TextField
              readOnly
              disabled
              fullWidth
              size="small"
              value={claimAmount}
              variant="outlined"
              placeholder="0"
              sx={{
                "& .MuiOutlinedInput-root": { bgcolor: "#fff" },
                "& .MuiInputBase-input": { color: 'black' }
              }}
            />
          </Box>

          <Box>
            <Typography sx={{ color: "black", fontFamily: "Inter" }}>Wallet Address</Typography>
            <TextField
              readOnly
              disabled
              fullWidth
              size="small"
              variant="outlined"
              value={address || ''}
              placeholder="0x567890ds98f675eda70990s8dfa0"
              sx={{
                "& .MuiOutlinedInput-root": { bgcolor: "#fff" },
                "& .MuiInputBase-input": { color: 'black' }
              }}
            />
          </Box>
          <Box mt={5} textAlign="center">
            <SubmitButton
              matches={matches}
              setAlertState={setAlertState}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  </Box>;
}

const steps = ['Initiated', 'Progress', 'Confirmed'];
function SubmitButton ({ matches, setAlertState=() => void(0) }) {

  const MLMContract = useMLMContract();

  const handleClaim = async () => {
    try {
      await MLMContract.claim()
      setAlertState({ open: true, severity: "success", message: 'Claimed successfully!' });
    } catch (error) {
      if (error.code === 'ACTION_REJECTED') {
        setAlertState({ open: true, severity: "error", message: 'User rejected transaction' });
      } else {
        const errorMessage = error.reason || error.message || "Went something wrong";
        setAlertState({ open: true, severity: "error", message: errorMessage });
      }
      return error;
    }
  };
  return <>
    <Button
      onClick={handleClaim}
      sx={{
        width: "90%",
        border: "none",
        color: "white",
        fontWeight: "700",
        fontFamily: "Inter",
        textTransform: 'none',
        background: "#0071BD",
        fontSize: matches ? "20px" : "25px",
        "&:hover": { background: "#0071BDa1", },
      }}
    >Claim</Button>
    {/*<TransferStepper*/}
    {/*  steps={steps}*/}
    {/*  title="Claim"*/}
    {/*  handleWait={handleSuccess}*/}
    {/*  isOpened={isOpenedStepper}*/}
    {/*  onClose={() => setIsOpenedStepper(false)}*/}
    {/*  description="The claiming process has been executed successfully"*/}
    {/*/>*/}
  </>;
}
